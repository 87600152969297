import { actionChannel, call, put, select, take } from 'redux-saga/effects'
import { logAction } from 'state/_sagas/_actions'
import { cardByIdSelector, setCard } from 'state/entities/cards'
import { setView, viewByIdSelector } from 'state/entities/views'
import { setSequenceIndex } from 'state/lead'
import { navigate } from 'state/router'
import {
  finishedInitializingPreview,
  rpPreviewModeSelector,
  setWipViewId,
  toggleHomeDeskLauncher,
  updatedWipCard,
  updateWebdeskInfo,
  wipViewIdSelector,
  wipWebdeskSelector
} from 'state/ui'
import {
  CardInterface,
  CardSequenceViewInterface,
  CardStackViewInterface
} from 'types'
import { Action } from 'typescript-fsa'

function* forceCardPreviewRerender(
  wipView: CardStackViewInterface | CardSequenceViewInterface,
  previewMode: string
) {
  // eslint-disable-next-line no-param-reassign
  wipView.cardIds = []
  yield put(setView({ view: wipView }))
  yield put(finishedInitializingPreview({ previewMode }))
}

function* handleFieldAction(
  fieldName: string,
  fieldValue: any,
  previewMode: string
) {
  const wipView = {
    ...((yield select(viewByIdSelector, yield select(wipViewIdSelector))) as
      | CardStackViewInterface
      | CardSequenceViewInterface)
  }

  // WIP VIEW FIELDS
  if (previewMode === 'viewsview') {
    if (fieldName === 'wipView.header.navigation') {
      wipView.header.navigation = fieldValue
      yield put(setView({ view: wipView }))
    }
    if (fieldName === 'wipView.header.title') {
      wipView.header.title = fieldValue
      yield put(setView({ view: wipView }))
    }
    if (fieldName === 'wipView.header.subtitle') {
      wipView.header.subtitle = fieldValue
      yield put(setView({ view: wipView }))
    }
    if (fieldName === 'wipView.header.footer') {
      wipView.header.footer = fieldValue
      yield put(setView({ view: wipView }))
    }
    if (fieldName === 'wipView.template') {
      wipView.template = fieldValue
      yield put(setSequenceIndex({ _id: wipView._id, index: 0 }))
      yield put(setView({ view: wipView }))
    }
  }

  // WIP CARD FIELDS
  if (previewMode === 'cardmodal') {
    const wipCard = yield select(cardByIdSelector, wipView.cardIds[0])

    if (fieldName === 'wipCard.header.alwaysShow') {
      wipCard.header.alwaysShow = fieldValue
      yield put(setCard({ card: wipCard }))
    }
    if (fieldName === 'wipCard.header.title') {
      wipCard.header.title = fieldValue
      yield put(setCard({ card: wipCard }))
    }
    if (fieldName === 'wipCard.props.title') {
      wipCard.props.title = fieldValue
      yield put(setCard({ card: wipCard }))
    }
    if (fieldName === 'wipCard.props.body') {
      wipCard.props.body = fieldValue
      yield put(setCard({ card: wipCard }))
    }
    if (fieldName === 'wipCard.props.url') {
      wipCard.props.url = fieldValue
      yield put(setCard({ card: wipCard }))
    }
    if (fieldName === 'wipCard.props.place') {
      wipCard.props.place = fieldValue
      yield put(setCard({ card: wipCard }))
    }
    if (fieldName === 'wipCard.props.chatbotId') {
      yield call(() => forceCardPreviewRerender(wipView, previewMode))
    }
    if (fieldName === 'wipCard.props.greeting.avatarName') {
      wipCard.props.greeting.avatarName = fieldValue
      yield put(setCard({ card: wipCard }))
    }
    if (fieldName === 'wipCard.props.greeting.greetingText') {
      wipCard.props.greeting.greetingText = fieldValue
      yield put(setCard({ card: wipCard }))
    }
    if (fieldName === 'wipCard.props.reviewsClientId') {
      yield call(() => forceCardPreviewRerender(wipView, previewMode))
    }
    if (fieldName === 'wipCard.props.disclaimer') {
      wipCard.props.disclaimer = fieldValue
      yield put(setCard({ card: wipCard }))
    }
    if (fieldName === 'wipCard.props.privacyLink') {
      wipCard.props.privacyLink = fieldValue
      yield put(setCard({ card: wipCard }))
    }
    // **************** INVENTORY CARD PREVIEW UPDATES ****************
    if (fieldName === 'wipCard.props.inventoryQuery') {
      yield call(() => forceCardPreviewRerender(wipView, previewMode))
    }
    if (fieldName === 'wipCard.props.noResultsMessage') {
      wipCard.props.noResultsMessage = fieldValue
      yield put(setCard({ card: wipCard }))
    }
    if (fieldName === 'wipCard.props.priceLabel') {
      yield call(() => forceCardPreviewRerender(wipView, previewMode))
    }
    if (fieldName === 'wipCard.props.showPrice') {
      yield call(() => forceCardPreviewRerender(wipView, previewMode))
    }
    if (fieldName === 'wipCard.props.btnText') {
      yield call(() => forceCardPreviewRerender(wipView, previewMode))
    }
    if (fieldName === 'wipCard.props.btnFontSize') {
      yield call(() => forceCardPreviewRerender(wipView, previewMode))
    }
    if (fieldName === 'wipCard.props.sort.field') {
      yield call(() => forceCardPreviewRerender(wipView, previewMode))
    }
    if (fieldName === 'wipCard.props.sort.order') {
      yield call(() => forceCardPreviewRerender(wipView, previewMode))
    }
  }

  // WIP WEBDESK FIELDS
  if (previewMode === 'launcherview') {
    const wipWebdesk = yield select(wipWebdeskSelector)

    if (fieldName === 'wipWebdesk.launcher.desktop') {
      wipWebdesk.launcher.desktop = fieldValue
      yield put(updateWebdeskInfo({ webdesk: wipWebdesk }))
      // change the state of the nav if setting the launchertype to home desk
      if (fieldValue === 'ca') {
        yield put(toggleHomeDeskLauncher({ isHome: true }))
      } else {
        yield put(toggleHomeDeskLauncher({ isHome: false }))
      }
    }
  }
  if (previewMode === 'behaviorview') {
    const wipWebdesk = yield select(wipWebdeskSelector)

    if (fieldName === 'wipWebdesk.appearance.color') {
      wipWebdesk.appearance.color = fieldValue
      yield put(updateWebdeskInfo({ webdesk: wipWebdesk }))
    }
    if (fieldName === 'wipWebdesk.appearance.navColor') {
      wipWebdesk.appearance.navColor = fieldValue
      yield put(updateWebdeskInfo({ webdesk: wipWebdesk }))
    }
    if (fieldName === 'wipWebdesk.appearance.position') {
      wipWebdesk.appearance.position = fieldValue
      yield put(updateWebdeskInfo({ webdesk: wipWebdesk }))
    }
    if (fieldName === 'wipWebdesk.appearance.horizontalPadding') {
      wipWebdesk.appearance.horizontalPadding = fieldValue
      yield put(updateWebdeskInfo({ webdesk: wipWebdesk }))
    }
    if (fieldName === 'wipWebdesk.appearance.verticalPadding') {
      wipWebdesk.appearance.verticalPadding = fieldValue
      yield put(updateWebdeskInfo({ webdesk: wipWebdesk }))
    }
    if (fieldName === 'wipWebdesk.behavior.disableTranslator') {
      wipWebdesk.behavior.disableTranslator = fieldValue
      yield put(updateWebdeskInfo({ webdesk: wipWebdesk }))
    }
  }
}

function* saga(dashboardActionHandler: any) {
  try {
    const dashboardAction = dashboardActionHandler.dashboardAction as Action<
      any
    >
    const previewMode = yield select(rpPreviewModeSelector)
    if (previewMode === 'none') return

    // ******************************** WIP WEBDESK ACTIONS *****************************************
    if (
      dashboardAction.type === 'wip/SET_WIP_WEBDESK' &&
      (previewMode === 'viewsview' || previewMode === 'behaviorview')
    ) {
      const newWebdesk = dashboardAction.payload.webdesk
      yield put(updateWebdeskInfo({ webdesk: newWebdesk }))
    }
    if (
      dashboardAction.type === 'wip/SET_LAUNCHER_CARD_IDS_FOR_WIP_WEBDESK' ||
      dashboardAction.type ===
        'wip/SET_TIME_TRIGGER_CARD_IDS_FOR_WIP_WEBDESK' ||
      dashboardAction.type === 'wip/SET_EXIT_TRIGGER_CARD_IDS_FOR_WIP_WEBDESK'
    ) {
      const wipWebdesk = yield select(wipWebdeskSelector)
      wipWebdesk.launcher.cardIds = dashboardAction.payload.cardIds
      yield put(updateWebdeskInfo({ webdesk: wipWebdesk }))
    }
    if (
      dashboardAction.type === 'wip/SEND_ACTIVE_TRIGGER_CARD_IDS_TO_PREVIEW'
    ) {
      const wipWebdesk = yield select(wipWebdeskSelector)
      const { triggerCardIds } = dashboardAction.payload
      if (triggerCardIds) {
        wipWebdesk.launcher.cardIds = triggerCardIds
        yield put(updateWebdeskInfo({ webdesk: wipWebdesk }))
        yield put(toggleHomeDeskLauncher({ isHome: false }))
      } else {
        // if there are no active triggers being created, show the home view for the other behavior settings
        yield put(toggleHomeDeskLauncher({ isHome: true }))
      }
    }

    // ******************************** WIP VIEW ACTIONS ********************************************
    if (dashboardAction.type === 'wip/SET_WIP_VIEW') {
      const newView = dashboardAction.payload.view as
        | CardStackViewInterface
        | CardSequenceViewInterface

      // add the new view to the state entities if it doesnt exist yet
      yield put(setView({ view: newView }))
      yield put(setWipViewId({ wipViewId: newView._id }))
    }
    if (dashboardAction.type === 'wip/SET_WIP_CARD_IDS_FOR_WIP_VIEW') {
      const wipView = (yield select(
        viewByIdSelector,
        yield select(wipViewIdSelector)
      )) as CardStackViewInterface | CardSequenceViewInterface
      wipView.cardIds = dashboardAction.payload.cardIds
      yield put(setView({ view: wipView }))
    }

    // ******************************** WIP CARD ACTIONS ********************************************
    if (dashboardAction.type === 'cards/SUCCESS_CREATE_OR_UPDATE_CARD') {
      const newCard = dashboardAction.payload.card as CardInterface
      yield put(setCard({ card: newCard }))
      if (newCard.template === 'chatbot') {
        const wipView = (yield select(
          viewByIdSelector,
          yield select(wipViewIdSelector)
        )) as CardStackViewInterface | CardSequenceViewInterface
        const ogWipViewIds = wipView.cardIds
        const minusWipViewIds = ogWipViewIds.filter(id => id !== newCard._id)
        // Force an update of the chatbot card. It has internal state methods we cant access from here
        wipView.cardIds = [...minusWipViewIds]
        yield put(setView({ view: wipView }))
        wipView.cardIds = [...ogWipViewIds]
        yield put(setView({ view: wipView }))
        yield put(finishedInitializingPreview({ previewMode }))
      }
    }

    // ******************************** SPECIAL FIELD UPDATES ***************************************
    if (dashboardAction.type === 'wip/UPDATE_WIP_BY_FIELD') {
      const fieldData = dashboardAction.payload.data
      const fieldName = Object.keys(fieldData)[0]
      const fieldValue = fieldData[fieldName].value
      const fieldStatus = fieldData[fieldName].status
      if (fieldStatus !== 'invalid') {
        yield call(() => handleFieldAction(fieldName, fieldValue, previewMode))
      }
    }

    // ******************************** CARD MODAL PREVIEW ACTIONS **********************************
    if (previewMode === 'cardmodal') {
      if (dashboardAction.type === 'wip/SET_WIP_CARD') {
        const wipCard = dashboardAction.payload.card as CardInterface
        const wipView = (yield select(
          viewByIdSelector,
          yield select(wipViewIdSelector)
        )) as CardStackViewInterface | CardSequenceViewInterface
        wipView.cardIds = [wipCard._id]
        yield put(setCard({ card: wipCard }))
        yield put(setView({ view: wipView }))
      }
      if (dashboardAction.type === 'wip/SET_WIP_CARD_IMAGE') {
        const wipView = {
          ...((yield select(
            viewByIdSelector,
            yield select(wipViewIdSelector)
          )) as CardStackViewInterface | CardSequenceViewInterface)
        }
        const wipCard = yield select(cardByIdSelector, wipView.cardIds[0])
        wipCard.props.image = dashboardAction.payload.imageSrc
        yield put(setCard({ card: wipCard }))
      }
      if (dashboardAction.type === 'wip/SET_WIP_CARD_GREETING_IMAGE') {
        const wipView = {
          ...((yield select(
            viewByIdSelector,
            yield select(wipViewIdSelector)
          )) as CardStackViewInterface | CardSequenceViewInterface)
        }
        const wipCard = yield select(cardByIdSelector, wipView.cardIds[0])
        wipCard.props.greeting.imageUrl = dashboardAction.payload.imageSrc
        yield put(setCard({ card: wipCard }))
      }
      if (dashboardAction.type === 'wip/SET_WIP_CARD_GALLERY') {
        const wipView = {
          ...((yield select(
            viewByIdSelector,
            yield select(wipViewIdSelector)
          )) as CardStackViewInterface | CardSequenceViewInterface)
        }
        const wipCard = yield select(cardByIdSelector, wipView.cardIds[0])
        wipCard.props.gallery = dashboardAction.payload.gallery
        yield put(setCard({ card: wipCard }))
      }
      if (dashboardAction.type === 'wip/SET_WIP_CARD_ACTION_BUTTONS') {
        const wipView = {
          ...((yield select(
            viewByIdSelector,
            yield select(wipViewIdSelector)
          )) as CardStackViewInterface | CardSequenceViewInterface)
        }
        const wipCard = yield select(cardByIdSelector, wipView.cardIds[0])
        wipCard.props.actionButtons = dashboardAction.payload.actionButtons
        yield put(setCard({ card: wipCard }))
      }
      if (dashboardAction.type === 'wip/SET_WIP_CARD_HOURS') {
        const wipView = {
          ...((yield select(
            viewByIdSelector,
            yield select(wipViewIdSelector)
          )) as CardStackViewInterface | CardSequenceViewInterface)
        }
        const wipCard = yield select(cardByIdSelector, wipView.cardIds[0])
        wipCard.props.hours = dashboardAction.payload.hours
        yield put(setCard({ card: wipCard }))
      }
      if (dashboardAction.type === 'wip/SET_WIP_CARD_FORM_DATA') {
        const wipView = {
          ...((yield select(
            viewByIdSelector,
            yield select(wipViewIdSelector)
          )) as CardStackViewInterface | CardSequenceViewInterface)
        }
        const wipCard = yield select(cardByIdSelector, wipView.cardIds[0])
        wipCard.props.formData = dashboardAction.payload.formData
        yield put(setCard({ card: wipCard }))
      }
      if (dashboardAction.type === 'wip/SET_WIP_CARD_REVIEW_SOURCES') {
        const wipView = {
          ...((yield select(
            viewByIdSelector,
            yield select(wipViewIdSelector)
          )) as CardStackViewInterface | CardSequenceViewInterface)
        }
        const wipCard = yield select(cardByIdSelector, wipView.cardIds[0])
        wipCard.props.reviewSources = dashboardAction.payload.reviewSources
        yield put(setCard({ card: wipCard }))
      }
      if (dashboardAction.type === 'wip/SET_WIP_CARD_FACEBOOK_PAGE_NAME') {
        const wipView = {
          ...((yield select(
            viewByIdSelector,
            yield select(wipViewIdSelector)
          )) as CardStackViewInterface | CardSequenceViewInterface)
        }
        const wipCard = yield select(cardByIdSelector, wipView.cardIds[0])
        wipCard.props.facebookPageName =
          dashboardAction.payload.facebookPageName
        yield put(setCard({ card: wipCard }))
      }
      if (dashboardAction.type === 'wip/SET_WIP_CARD_FACEBOOK_PAGE_EXISTS') {
        const wipView = {
          ...((yield select(
            viewByIdSelector,
            yield select(wipViewIdSelector)
          )) as CardStackViewInterface | CardSequenceViewInterface)
        }
        const wipCard = yield select(cardByIdSelector, wipView.cardIds[0])
        wipCard.props.facebookPageExists =
          dashboardAction.payload.facebookPageExists
        yield put(setCard({ card: wipCard }))
      }
      if (dashboardAction.type === 'wip/SET_WIP_CARD_FILTERS') {
        const wipView = {
          ...((yield select(
            viewByIdSelector,
            yield select(wipViewIdSelector)
          )) as CardStackViewInterface | CardSequenceViewInterface)
        }
        yield call(() => forceCardPreviewRerender(wipView, previewMode))
      }
      if (dashboardAction.type === 'wip/SET_WIP_CARD_DATA_FIELDS') {
        const wipView = {
          ...((yield select(
            viewByIdSelector,
            yield select(wipViewIdSelector)
          )) as CardStackViewInterface | CardSequenceViewInterface)
        }
        yield call(() => forceCardPreviewRerender(wipView, previewMode))
      }

      yield put(updatedWipCard({}))
    }

    // ensure we got back to showing the wipview after any changes
    if (previewMode === 'viewsview' || previewMode === 'cardmodal') {
      yield put(navigate({ viewId: yield select(wipViewIdSelector) }))
    }
  } catch (e) {
    yield put(logAction({ error: e }))
    // eslint-disable-next-line no-console
    console.error('Error handling Dashboard Action in EP2 preview:', e)
  }
}

export function* handleDashboardMessageSaga() {
  const dashboardActionChannel = yield actionChannel(
    'sagas/HANDLE_DASHBOARD_ACTION'
  )
  while (true) {
    const { payload } = yield take(dashboardActionChannel)
    yield call(saga, payload)
  }
}
